<template>
  <div class="landing-page-container">
    <div class="landing-page-wrapper">
      <div class="slide landing-page-container-one">
        <header>
          <router-link to="/">
            <div class="logo-header">
              <img
                class="animate__animated animate__backInUp animate__delay-2s logo-image"
                src="./../assets/scenti-memoji.png"
                alt="Images"
              />
              <h2 tabindex="1">Scenti</h2>
            </div>
          </router-link>
          <p tabindex="2">Say Hi</p>
        </header>
        <div class="landing-page">
          <section class="landing-page__one">
            <div class="landing-page__one--name"></div>
            <div class="landing-page__one--description">
              <p class="animated fadeInDown">
                Hello,
                <span class="hello">👋</span>
              </p>
              <p class="animated__delay-2s animated fadeInLeft">
                My name is Chidimma, but my friends call me Scenti.
              </p>
              <p class="animated__delay-2s animated fadeInLeft">
                I am a Frontend Developer, I write
                <span class="landing-page__color--angular">Angular</span>,a little bit of
                <span class="landing-page__color--vue">Vue</span> and 
                <span class="landing-page__color--react"> React</span>
              </p>
            </div>
          </section>
          <section class="landing-page__two">
            <nav>
              <ul>
                <li tabindex="3">
                  <a v-on:click="about">About</a>
                </li>
                <li tabindex="4">
                  <a v-on:click="portfolio">Portfolio</a>
                </li>
                <li tabindex="5">
                  <a v-on:click="writings">Writings</a>
                </li>
                <li>
                  <a tabindex="6" v-on:click="contact">Contact</a>
                </li>
              </ul>
            </nav>
            <div class="images">
              <!-- <video
                width="320"
                height="240"
                src="./../assets/memoji-video.mov"
              ></video> -->
              <img
                class="animate__animated animate__backInUp animate__delay-2s"
                src="./../assets/scenti-memoji.png"
                alt="Images"
              />
            </div>
          </section>
        </div>
      </div>
      <div id="about" class="slide">
        <div class="about-page">
          <section class="about-page__one">
            <h1>About Me</h1>
            <p class="">
              I am currently looking for my next opportunity. I'm a passionate and driven
              individual who <br/> loves coding, traveling and meeting new people.
            </p>
            <p>I strongly believe in exposure and gaining experience.</p>
            <p>I am a Frontend Engineer with hands on experience developing websites focused on 
              responsive <br/> design and accessiblity, and with every line of code I write, I strive to 
              make the web a beautiful place.
            </p>
            <p>
              Previously worked at Tangerine Africa, Software Business Solutions
              Consulting(SBSC) and TradeDepot Inc. 
            </p>
            <p>When i'm not working I volunteer for different organizations.</p>
            <button>Download my resume</button>
          </section>
          <section class="about-page__two">
            <img src="./../assets/no-policy.gif" alt="Gif" />
          </section>
        </div>
      </div>
      <div id="portfolio" class="slide">
        <div class="portfolio">
          <h3 class="portfolio__title">Projects 📽 🖥</h3>
        <div class="card">
          <div class="portfolio__projects">
            <div v-for="project of projects" :key="project.title" class="portfolio__projects--card">
              <img :src="getImgUrl(project.image)" v-bind:alt="project.image">
          </div>
          </div>
        </div>
        </div>
      </div>
      <div id="writings" class="slide">
        <div class="writing-page">
          <h3>Writings 📝</h3>
          <div class="card">
            <div class="writing-page__cards">
            <div v-for="blog of blogs" :key="blog.title" class="writing-page__cards--card">
              <img :src="`${blog.thumbnail}`" v-bind:alt="blog.thumbnail"/>
              <h4>{{ blog.title }}</h4>
              <!-- <p>{{ blog.content }}</p> -->
            </div>
          </div>
          </div>
        </div>
      </div>
      <div id="contact" class="slide">
        <div class="contact">
          <h2>Contact ☎️ </h2>
        <div class="contact-page">
          <h1>I take on freelance opportunities</h1>
          <p>Tell me about your available job opportunities or just say hi :)</p>

          <form action="">
            <p>🎧</p>
            <!-- <i class="fab fa-apple"></i> -->
            <div class="name"><label for="name"><i class="far fa-user"></i>name</label>
              <input type="text" placeholder="your name" required>
            </div>
            <div class="email"><label for="email"><i class="far fa-envelope"></i>email</label>
              <input type="mail" placeholder="your@mail.com" required>
            </div>
            <div class="topic"><label for="topic"><i class="fas fa-align-justify"></i>topic</label>
              <input type="text" placeholder="your topic" required>
            </div>
            <div class="message"><label for="text"><i class="far fa-comment-dots"></i>message</label>
              <textarea name="" id="" cols="30" rows="10" placeholder="your message"></textarea>
            </div>
            <button><i class="fas fa-power-off"></i></button>
            <div class="shadow"></div>
          </form>
          <p>Dont like forms??? Just send an email </p>
          <p>Reach out to me on social media</p>
          <div>
            instagram
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "LandingPage",
  methods: {
    about() {
      document.getElementById("about").scrollIntoView(true);
    },

    portfolio() {
      document.getElementById("portfolio").scrollIntoView(true);
    },

    writings() {
      document.getElementById("writings").scrollIntoView(true);
    },

    contact() {
      document.getElementById("contact").scrollIntoView(true);
    },
    getImgUrl(image) {
      return require('./../assets/'+image)
    }
  },
  data() {
    return {
      blogs: [],
      errors: [],
      projects: [
        {
          title: 'Title 1',
          image: 'game1.png',
          description: 'This is title one description'
        },
        {
          title: 'Title 2',
          image: 'game1.png',
          description: 'This is title two description'
        },
        {
          title: 'Title 3',
          image: 'game1.png',
          description: 'This is title three description'
        },
        {
          title: 'Title 4',
          image: 'game1.png',
          description: 'This is title four description'
        }
      ]
    };
  },

  created() {
    axios
      .get(
        "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@cognoscenti"
      )
      .then((response) => {
        this.blogs = response.data.items;
        
        console.log("blog", response.data.items);
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
};
</script>

<style lang="scss" scoped>
.landing-page-container {
  width: 100vh;
  height: 100vw;
  transform: rotate(-90deg) translateX(-100vh);
  transform-origin: top left;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  scrollbar-width: none;
  -ms-overflow-style: none;
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAPCAMAAAAxmgQeAAAACVBMVEX///8AAAD///9+749PAAAAAXRSTlMAQObYZgAAAD5JREFUGNN1j9EKADAIAnP//9GjFq6w7iU4DNRsB06cb44TJ+UzYUcniqnqoA6ag/62JhjKIU0rxx1lKveuXJ5UAQs/G/2vAAAAAElFTkSuQmCC),
    auto;

  .landing-page-wrapper {
    display: flex;
    flex-direction: row;
    width: 500vw;
    transform: rotate(90deg) translateY(-100vh);
    transform-origin: top left;
  }

  .slide {
    width: 100vw;
    height: 100vh;

    &:nth-child(odd) {
      background: #d1d1e9;
    }

    &:nth-child(even) {
      background: #fffffe;
    }
  }

  header {
    margin: 0px 40px;
    display: grid;
    grid-template-columns: 2fr 1fr;

    p {
      text-align: right;
    }
  }
}

.logo-header {
  display: flex;

  .logo-image {
    height: 100px;
  }
}

.landing-page {
  display: grid;
  color: #2b2c34;
  grid-template-columns: 2fr 1fr;
  padding: 30px 100px;

  .landing-page__one {
    position: relative;
    font-size: 25px;
    font-weight: bold;
    margin: 200px 0px;

    .landing-page__one--name {
      &::before {
        position: absolute;
        content: "";
        left: 0;
        width: 50px;
        height: 2px;
        background-color: #6246ea;
      }
      &::after {
        position: absolute;
        content: "Cognoscenti";
        color: #2b2c34;
        font-size: 20px;
        left: 60px;
        top: -12px;
      }
    }

    .landing-page__color--angular {
      color: red
    }

    .landing-page__color--vue {
        color: green;
    }

    .landing-page__color--react {
      color: blue
    }
  }

  .landing-page__two {
    position: relative;

    nav {
      position: absolute;
      transform: rotate(270deg);
      left: 300px;
      top: 200px;

      ul {
        list-style-type: none;

        li {
          display: inline;
          padding: 0px 10px;

          a {
            color: #2b2c34;
            text-decoration: none;

            &:hover {
              color: #6246ea;
              cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAPCAMAAAAxmgQeAAAACVBMVEX///8AAAD///9+749PAAAAAXRSTlMAQObYZgAAAD5JREFUGNN1j9EKADAIAnP//9GjFq6w7iU4DNRsB06cb44TJ+UzYUcniqnqoA6ag/62JhjKIU0rxx1lKveuXJ5UAQs/G/2vAAAAAElFTkSuQmCC),
                auto;
            }

            &:focus {
              outline: 0;
              border: 1px solid #6246ea;
            }
          }
        }
      }
    }

    .images {
      img:nth-child(1) {
        position: absolute;
        // width: 250px;
        // height: 250px;
        left: 30px;
        bottom: 30%;
      }
    }
  }
}

.about-page {
  display: grid;
  color: #2b2c34;
  grid-template-columns: 2fr 1fr;
  // .about-page__name {
  // 	&::before {
  // 		position: absolute;
  // 		content: '';
  // 		left: 0;
  // 		width: 50px;
  // 		height: 2px;
  // 		background-color: #6246ea;
  // 	}
  // 	&::after {
  // 		position: absolute;
  // 		content: 'Cognoscenti';
  // 		color: #2b2c34;
  // 		font-size: 20px;
  // 		left: 60px;
  // 		top: -12px;
  // 	}
  // }
}

.writing-page {
  .writing-page__cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;

    .writing-page__cards--card {
      background-color: #a786df;
    }
  }
  .writing-page__cards--card img {
  object-fit: cover;
  width: 100%;
  max-height: 100%;
}
}

.portfolio{
  .portfolio__projects {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fit,minmax(500px,1fr));
}
}

.card {
  height: 80vh;
  width: 80vw;
  margin:70px;
  background-color: white;
  overflow: auto;
}

.portfolio__projects--card img {
  object-fit: cover;
  width: 100%;
  max-height: 100%;
  filter: brightness(50%) grayscale(100%);
  transition: filter 1000ms;
}

.portfolio__projects--card img:hover {
    filter: none;
    cursor: crosshair;
}

.contact, .portfolio, .writing-page, .about-page {
  padding: 30px 100px;
}

form {
  text-align: center;
  padding: 20px;
  box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  border-radius: 20px;
  background: rgb(232, 232, 232);
  background: linear-gradient(
    180deg,
    rgba(232, 232, 232, 1) 0%,
    rgba(218, 218, 218, 1) 50%,
    rgba(154, 154, 154, 1) 100%
  );
  transform-style: preserve-3d;
  transform: perspective(2500px) translate3d(0px, 0px, -150px) rotateX(35deg);
  transition: transform 0.5s ease;
  i {
    margin-right: 10px;
    font-size: 16px;
    transition: color 0.3s ease, box-shadow 0.3s ease;
  }
  > i {
    font-size: 40px;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      170deg,
      rgba(0, 0, 0, 1) 20%,
      rgba(255, 255, 255, 1) 50%,
      rgba(0, 0, 0, 1) 80%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
  }
  input,
  textarea {
    transform-style: preserve-3d;

    border: 0;
    padding: 10px;
    outline: none;
    width:calc(100% - 20px);
    border-radius: 10px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    &:hover,
    &:focus {
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
    }
  }
  .name,
  .text,
  .email,
  .topic,
  .message {
    &:hover {
      i {
        color: white;
        text-shadow: 0px 0px 13px #3dd3ff, 0px 0px 13px #14e9ff;
      }
    }
  }
  .message {
    margin-bottom:10px;
  }
  button {
    border: 0;
    outline: none;
    width: 40px;
    height: 40px;

    font-size: 30px;
    background: rgb(232, 232, 232);
    background: linear-gradient(
      135deg,
      rgba(232, 232, 232, 1) 0%,
      rgba(218, 218, 218, 1) 50%,
      rgba(154, 154, 154, 1) 100%
    );
    border-radius: 50%;
    position: relative;
    transform-style: preserve-3d;
    transform: translateZ(1px);
    i {
      width: 100%;
      height: 80%;
      font-size: 25px;
      font-weight: 200;
      color: #5f5f5f;
    }
    &:before {
      content: "";
      height: 40px;
      width: 40px;
      position: absolute;
      left: 0;
      bottom: -10px;
      -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 100%, 0% 100%);
      clip-path: polygon(0 40%, 100% 40%, 100% 100%, 0% 100%);
      border-bottom-left-radius: 50%;
      border-bottom-right-radius: 50%;
      transform: translateZ(-1px);
      background: rgb(37, 37, 37);
      transition: bottom 0.5s ease;
      background: linear-gradient(
        90deg,
        rgba(37, 37, 37, 1) 0%,
        rgba(99, 99, 99, 1) 15%,
        rgba(128, 128, 128, 1) 50%,
        rgba(99, 99, 99, 1) 85%,
        rgba(37, 37, 37, 1) 100%
      );
    }
    &:hover {
      i {
        color: white;
        text-shadow: 0px 0px 13px #3dd3ff, 0px 0px 13px #14e9ff;
      }
    }
  }
  .shadow {
    background: black;
    display: block;
    position: absolute;
    mix-blend-mode: multiply;
    left:calc(50% - 100px);
    bottom: -50px;
    height: 15px;
    width: 200px;
    border-radius: 50%;
    filter: blur(8px);
    transition: opacity 0.5s ease;
  }

  label {
    text-align: left;
    font-size: 12px;
    color: #636363;
    margin: 10px 0;
    width: 100%;
    display: block;
    font-family: sans-serif;
    font-weight: 300;
    text-transform: uppercase;
  }
  &:before {
    transform: translateZ(-2px);
    position: absolute;
    content: "";
    height: 50px;
    bottom: -30px;
    left: 0;
    width: 100%;
    display: block;
    background: rgb(37, 37, 37);
    transition: bottom 0.5s ease;
    background: linear-gradient(
      90deg,
      rgba(37, 37, 37, 1) 0%,
      rgba(99, 99, 99, 1) 15%,
      rgba(128, 128, 128, 1) 50%,
      rgba(99, 99, 99, 1) 85%,
      rgba(37, 37, 37, 1) 100%
    );
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  &:after {
    transform: translateZ(1px);
    position: absolute;
    left: calc(50% - 15px);
    bottom: -20px;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(36, 36, 36, 1) 100%
    );
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
    content: "";
    height: 10px;
    border-radius: 20px;
    width: 30px;
    display: block;
    transition: bottom 0.5s ease;
    transform: translateZ(-2px);
  }
  &:hover {
    transform: perspective(2500px) translate3d(0px, 0px, -150px) rotateX(0deg);
    &:before {
      bottom: 30px;
    }
    &:after {
      bottom: 20px;
    }
    .shadow {
      opacity: 0;
    }
    button {
      &:before {
        bottom: 0;
      }
    }
  }
}

</style>